<template>
  <div style="margin: 20px;">
    <h3>Minutes<a type="text" style="font-size: 14px; color: green;" @click="add()"> New <i class="fas fa-plus-square"/></a></h3>
    <el-row>
      <el-alert
        v-if="request_data_add > 0"
        :title="'(' + request_data_add + ') New minutes added. Click refresh button to reload.'"
        type="success"
        show-icon
      />
    </el-row>
    <el-row>
      <el-alert
        v-if="request_data_update > 0"
        :title="'(' + request_data_update + ') Minutes updated. Click refresh button to reload.'"
        type="success"
        show-icon
      />
    </el-row>
    <el-row>
      <el-card shadow="never">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form :inline="true" label-position="right">
              <el-form-item>
                <el-date-picker v-model="year_minute_s" type="year" size="medium" placeholder="Year" style="width: 100px;" clearable />
              </el-form-item>
              <el-form-item>
                <el-select v-model="type_s" size="medium" placeholder="Minutes" style="width: 220px" clearable>
                  <el-option v-for="select in select_minutes_label" :key="select.label" :label="select.label" :value="select.label" />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input v-model="venue_s" size="medium" placeholder="Venue" style="width: 220px;" clearable />
              </el-form-item>
              <el-form-item>
                <el-tooltip content="Search" placement="top">
                  <el-button :icon="icon_search" type="primary" size="medium" @click="search()" />
                </el-tooltip>
              </el-form-item>
              <el-form-item>
                <el-tooltip content="Refresh" placement="top">
                  <el-button :icon="icon_refresh" type="info" size="medium" @click="refresh()" />
                </el-tooltip>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" align="right">
            <el-form :inline="true" label-position="right">
              <el-form-item>
                <el-checkbox v-model="naf">No Attached File</el-checkbox>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row>
          <el-table v-loading="loading" ref="singleTable" :data="minutes" size="mini" style="width: 100%; font-size: 14px;" highlight-current-row @current-change="handleCurrentChange">
            <el-table-column type="expand" label="..." width="48">
              <template slot-scope="props">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <p v-if="props.row.type === 'Academic Scholarship'" class="para"><strong>Minutes: </strong>{{ props.row.type }}</p>
                    <p v-if="props.row.type === 'Administrative Scholarship'" class="para"><strong>Minutes: </strong>{{ props.row.type }}</p>
                    <p v-if="props.row.type === 'APB'" class="para"><strong>Minutes: </strong>{{ props.row.type }} - Academic Personnel Board</p>
                    <p v-if="props.row.type === 'NAPB'" class="para"><strong>Minutes: </strong>{{ props.row.type }} - Non-Academic Personnel Board</p>
                    <p v-if="props.row.type === 'BOR'" class="para"><strong>Minutes: </strong>{{ props.row.type }} - Board of Regents</p>
                    <p v-if="props.row.type === 'UAC'" class="para"><strong>Minutes: </strong>{{ props.row.type }} - University Academic Council</p>
                    <p v-if="props.row.type === 'UADCO'" class="para"><strong>Minutes: </strong>{{ props.row.type }} - University Administrative Council</p>
                    <p v-if="props.row.minute_no" class="para"><strong>Minute no.: </strong>{{ props.row.minute_no }}</p>
                    <p class="para"><strong>Date: </strong>{{ getFormattedDate(props.row.date,'MMMM DD, Y') }}</p>
                    <p style="word-break: break-word;" class="para"><strong>Venue: </strong>{{ props.row.venue }}</p>
                    <p v-if="props.row.personnel_id" class="para"><strong>Secretary: </strong>{{ props.row.personnel.fullname }}</p>
                    <p v-if="props.row.doc_code" class="para"><strong>Document code: </strong>{{ props.row.doc_code }}</p>
                    <p v-if="props.row.rev_num" class="para"><strong>Revision no.: </strong>{{ props.row.rev_num }}</p>
                    <p v-if="props.row.imp_date" class="para"><strong>Implementation date: </strong>{{ getFormattedDate(props.row.imp_date,'MMMM DD, Y') }}</p>
                    <p v-if="props.row.con_num" class="para"><strong>Control no.: </strong>{{ props.row.con_num }}</p>
                    <p v-if="props.row.add_by_personnel" class="para">
                      <strong>Added by: </strong>
                      <span :title="getFormattedDate(props.row.created_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ props.row.add_by_personnel.name }}</span>
                    </p>
                    <p v-if="props.row.update_by_personnel" class="para">
                      <strong>Updated by: </strong>
                      <span :title="getFormattedDate(props.row.updated_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ props.row.update_by_personnel.name }}</span>
                    </p>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="Minutes" width="200" />
            <el-table-column label="Date" width="200">
              <template slot-scope="props">
                {{ getFormattedDate(props.row.date,'MMMM DD, Y') }}
              </template>
            </el-table-column>
            <el-table-column label="Venue" width="700">
              <template slot-scope="scope">
                <span v-if="scope.row.venue.length < 90" style="word-break: break-word"> {{ scope.row.venue }} </span>
                <span v-else style="word-break: break-word"> {{ scope.row.venue.substring(0, 90) + " ..." }} </span>
              </template>
            </el-table-column>
            <el-table-column width="" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.path" style="word-break: break-word"><el-tag type="success" effect="plain" size="medium" style="color: #4CAF50 !important; border-color: #4CAF50 !important; width: 120px;">File Uploaded</el-tag></span>
                <span v-else style="word-break: break-word"><el-tag type="danger" effect="plain" size="medium" style="width: 120px;">No Attached File</el-tag></span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="Operation (s)" width="200" align="right">
              <template slot-scope="scope">
                <el-button-group>
                  <el-tooltip v-if="scope.row.resolutions.length" :key="'iftpm1' + scope.row.id" content="View resolution (s)" placement="top">
                    <el-button :key="'ifbnm2' + scope.row.id" type="primary" icon="el-icon-view" size="mini" style="margin-right: 10px;" @click="view(scope.row)" />
                  </el-tooltip>
                  <el-tooltip v-if="scope.row.path" :key="'iftpm3' + scope.row.id" content="View Attached File" placement="top">
                    <el-button :key="'ifbnm4' + scope.row.id" type="primary" icon="el-icon-document" size="mini" style="margin-right: 10px;" @click="viewMinutesFile(scope.row.path)" />
                  </el-tooltip>
                  <el-tooltip :key="'tpm5' + scope.row.id" content="Edit" placement="top">
                    <el-button :key="'tpm6' + scope.row.id" type="primary" icon="el-icon-edit" size="mini" style="margin-right: 10px;" @click="edit(scope.$index)" />
                  </el-tooltip>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
          <div align="center" style="margin-top: 10px;">
            <el-pagination :page-size="meta.per_page" :pager-count="5" :total="meta.total" :current-page.sync="meta.page" layout="total, prev, pager, next" @current-change="getAll()"/>
          </div>
        </el-row>
      </el-card>
    </el-row>
    <el-dialog v-if="selected" :visible.sync="editable" :title="minutes_title" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" top="5vh" width="70%">
      <el-row style="margin: -50px 0;">
        <el-divider />
        <p><strong>Note:</strong> Fields marked with asterisk (<span style="color: #f56c6c; font-weight: bold;">*</span>) are required to be filled.</p>
        <el-form ref="selected" :model="selected" :rules="rules">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="8">
              <el-form-item label="Minutes" prop="type">
                <el-select v-if="editable" v-model="selected.type" size="medium" placeholder="Please select minutes" clearable>
                  <el-option v-for="select in select_minutes_label" :key="select.label" :label="select.label" :value="select.label" />
                </el-select>
              </el-form-item>
              <el-form-item v-if="selected.type === 'BOR' || selected.type === 'UAC' || selected.type === 'UADCO'" label="Minute no." prop="minute_no">
                <el-input v-if="editable" v-model.number="selected.minute_no" type="number" min="1" size="medium" placeholder="Please input memo no." style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Date" prop="date">
                <el-date-picker v-if="editable" v-model="selected.date" type="date" size="medium" placeholder="Please pick a date" style="width:100%" clearable />
              </el-form-item>
              <el-form-item label="Venue" prop="venue">
                <el-input v-if="editable" v-model="selected.venue" :autosize="{ minRows: 6 }" type="textarea" maxlength="500" show-word-limit placeholder="Please input venue" style="width: 100%; word-break: keep-all;" />
              </el-form-item>
              <el-form-item label="Secretary">
                <select-personnel v-if="editable" :post="selected.personnel" @setPersonnel="selected.personnel_id = $event" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="8">
              <el-form-item label="Document code">
                <el-input v-model="selected.doc_code" size="medium" placeholder="Please input document code" style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Revision no.">
                <el-input v-model.number="selected.rev_num" type="number" min="1" size="medium" placeholder="Please input revision no." style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Implementation date">
                <el-date-picker v-model="selected.imp_date" type="date" size="medium" placeholder="Please input implementation date" style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Control no.">
                <el-input v-model="selected.con_num" size="medium" placeholder="Please input control no." style="width: 100%;" clearable />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="8">
              <el-form-item label="Attached File">
                <minutes-uploader-component v-if="editable" :path.sync="selected.path" class="my-upload-job" @setAttachment="selected.path = $event" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="submitForm('selected')">Save</el-button>
        <el-button type="danger" size="medium" @click="submitClose('selected')">Cancel</el-button>
      </span>
    </el-dialog>
    <el-dialog v-if="resolution_selected" :visible.sync="view_resolution" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="closeListResolutions" top="5vh" width="70%">
      <h2 style="color: #28a73a;">Minutes of the {{ resolution_selected.minute_no }} {{ resolution_selected.type }} Meeting</h2>
      <h2 v-if="resolutions.length > 1">Approved {{ type_s }} Resolutions</h2>
      <h2 v-else>Approved {{ type_s }} Resolution</h2>
      <el-table v-loading="loading1" ref="singleTable" :data="resolutions" size="mini" style="width: 100%; font-size: 14px;" highlight-current-row @current-change="handleCurrentChange">
        <el-table-column type="expand">
          <template slot-scope="props">
            <p v-if="props.row.reso_type === 'BOR'" class="para"><strong>Resolution: </strong>BOR - Board of Regents</p>
            <p v-if="props.row.reso_type === 'UAC'" class="para"><strong>Resolution: </strong>UAC - University Academic Council</p>
            <p v-if="props.row.reso_type === 'UADCO'" class="para"><strong>Resolution: </strong>UADCO - University Administrative Council</p>
            <p class="para"><strong>Year: </strong>{{ props.row.year }}</p>
            <p class="para"><strong>Resolution no.: </strong>{{ props.row.resolution_no }}</p>
            <p style="word-break: break-word;" class="para"><strong>Title: </strong>{{ props.row.title }}</p>
            <p v-if="props.row.doc_code" class="para"><strong>Document code: </strong>{{ props.row.doc_code }}</p>
            <p v-if="props.row.rev_num" class="para"><strong>Revision no.: </strong>{{ props.row.rev_num }}</p>
            <p v-if="props.row.imp_date" class="para"><strong>Implementation date: </strong>{{ getFormattedDate(props.row.imp_date,'MMMM DD, Y') }}</p>
            <p v-if="props.row.con_num" class="para"><strong>Control no.: </strong>{{ props.row.con_num }}</p>
            <p v-if="props.row.add_by_personnel" class="para">
              <strong>Added by: </strong>
              <span :title="getFormattedDate(props.row.created_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ props.row.add_by_personnel.name }}</span>
            </p>
            <p v-if="props.row.update_by_personnel" class="para">
              <strong>Updated by: </strong>
              <span :title="getFormattedDate(props.row.updated_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ props.row.update_by_personnel.name }}</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="reso_type" label="Resoluton" width="100" />
        <el-table-column prop="year" label="Year" width="100" />
        <el-table-column prop="resolution_no" label="Reso. no." width="100" />
        <el-table-column label="Title" width="709">
          <template slot-scope="scope">
            <span v-if="scope.row.title.length < 90" style="word-break: break-word"> {{ scope.row.title }} </span>
            <span v-else style="word-break: break-word"> {{ scope.row.title.substring(0, 90) + " ..." }} </span>
          </template>
        </el-table-column>
        <el-table-column width="" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.path" style="word-break: break-word"><el-tag type="success" effect="plain" size="medium" style="color: #4CAF50 !important; border-color: #4CAF50 !important; width: 120px;">File Uploaded</el-tag></span>
            <span v-else style="word-break: break-word"><el-tag type="danger" effect="plain" size="medium" style="width: 120px;">No Attached File</el-tag></span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Operation" width="100" align="center">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.path" :key="'iftpm9'" content="View Attached File" placement="top">
              <el-button :key="'ifbnm10'" type="primary" icon="el-icon-document" size="mini" @click="viewResolutionFile(scope.row.path)" />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div align="center" style="margin-top: 10px;">
        <el-pagination :page-size="meta_r.per_page" :pager-count="5" :total="meta_r.total" :current-page.sync="meta_r.page" layout="total, prev, pager, next" @current-change="getListResolutions"/>
      </div>
      <!--
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" size="medium" @click="closeListResolutions()">Cancel</el-button>
      </span>
      -->
      <el-dialog
        :visible.sync="view_attach_form_reso"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="closeRosolutionFile"
        :append-to-body="true"
        width="60%"
        top="5vh">
        <div>
          <embed :key="view_file_reso" :src="view_file_reso" style="width: 100%; height: 700px;">
        </div>
      </el-dialog>
    </el-dialog>
    <el-dialog
      :visible.sync="view_attach_form"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeMinutesFile"
      width="60%"
      top="5vh">
      <div>
        <embed :key="view_file" :src="view_file" style="width: 100%; height: 700px;">
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { Minutes } from '@/api/records/minutes'
import { Resolutions } from '@/api/records/resolutions'
import { Notification } from 'element-ui'
import SelectPersonnel from '@/views/layout/components/SelectPersonnel'
import MinutesUploaderComponent from '@/views/layout/components/Records/MinutesUploaderComponent'

export default {
  name: 'Minutes',
  components: { SelectPersonnel, MinutesUploaderComponent },
  data() {
    return {
      loading: false,
      loading1: false,
      personnel: null,
      minutes: [],
      resolutions: [],
      meta: {
        page: 1
      },
      meta_r: {
        page: 1
      },
      editable: false,
      editable_monitoring: false,
      minutes_title: null,
      select_minutes_label: [
        { label: 'Academic Scholarship' },
        { label: 'Administrative Scholarship' },
        { label: 'APB' },
        { label: 'NAPB' },
        { label: 'BOR' },
        { label: 'UAC' },
        { label: 'UADCO' }
      ],
      year_minute_s: null,
      type_s: null,
      venue_s: null,
      naf: false,
      path_s: null,
      selected: {
        type: [],
        minute_no: '',
        date: '',
        venue: '',
        personnel_id: null,
        doc_code: '',
        rev_num: '',
        imp_date: '',
        con_num: '',
        path: ''
      },
      disposition: null,
      location: null,
      rules: {
        type: [
          { required: true, message: 'Minutes is required' }
        ],
        minute_no: [
          { required: true, message: 'Minute no. is required' },
          { type: 'number', message: 'Minute no. must be a number' }
        ],
        date: [
          { required: true, message: 'Date is required' }
        ],
        venue: [
          { required: true, message: 'Venue is required' },
          { max: 500, message: 'Length should be 500' }
        ]
      },
      resolution_selected: null,
      view_resolution: false,
      request_data_add: 0,
      request_data_update: 0,
      cur_personnel_id: this.$store.getters.user.currentUser.id,
      base_url: this.$store.getters.base_url,
      view_file: null,
      view_attach_form: false,
      view_file_reso: null,
      view_attach_form_reso: false,
      icon_search: 'el-icon-search',
      icon_refresh: 'el-icon-refresh'
    }
  },
  watch: {
    naf: function() {
      this.meta.page = 1
      if (this.naf === true) {
        this.year_minute_s = null
        this.type_s = null
        this.venue_s = null
        this.path_s = 1
      } else {
        this.year_minute_s = null
        this.type_s = null
        this.venue_s = null
        this.path_s = null
      }
      this.getAll()
    }
  },
  created: function() {
    this.$echo.channel('minutes').listen('RecordsMinutesEvent', (res) => {
      this.action = res.data
      if (this.action.action === 'add') {
        if (this.action.action_by !== this.cur_personnel_id) {
          this.request_data_add = this.request_data_add + 1
        }
      }
      if (this.action.action === 'edit') {
        if (this.action.action_by !== this.cur_personnel_id) {
          this.request_data_update = this.request_data_update + 1
        }
      }
    })
    this.getAll()
  },
  destroyed() {
    this.$echo.leave('minutes')
  },
  methods: {
    getAll() {
      this.loading = true
      this.selected = null
      if (this.year_minute_s !== null) {
        this.year_minute_s = this.getFormattedYear(this.year_minute_s)
      }
      Minutes({
        page: this.meta.page,
        year: this.year_minute_s,
        type: this.type_s,
        venue: this.venue_s,
        path: this.path_s
      }).then(res => {
        this.minutes = res.data.minutes
        this.meta = res.data.meta
        this.loading = false
        this.icon_search = 'el-icon-search'
        this.icon_refresh = 'el-icon-refresh'
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    search() {
      this.icon_search = 'el-icon-loading'
      this.meta.page = 1
      this.getAll()
    },
    refresh() {
      this.icon_refresh = 'el-icon-loading'
      this.type_s = null
      this.year_minute_s = null
      this.venue_s = null
      this.naf = false
      this.path_s = null
      this.request_data_add = 0
      this.request_data_update = 0
      this.meta.page = 1
      this.getAll()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          Notification.error({
            title: 'Error',
            message: 'Unable to save',
            duration: 5 * 1000
          })
          return false
        }
      })
    },
    submitClose(formName) {
      this.$refs[formName].resetFields()
      this.editable = false
    },
    // viewMinutesFile(file) {
    //   window.open(this.base_url + '/records/minutes/download/' + file, '_blank')
    // },
    viewMinutesFile(path) {
      this.view_file = this.base_url + '/records/minutes/download/' + path
      this.view_attach_form = true
    },
    closeMinutesFile() {
      this.view_file = null
      this.view_attach_form = false
    },
    // viewResolutionFile(file) {
    //   window.open(this.base_url + '/records/resolutions/download/' + file, '_blank')
    // },
    viewResolutionFile(path) {
      this.view_file_reso = this.base_url + '/records/resolutions/download/' + path
      this.view_attach_form_reso = true
    },
    closeRosolutionFile() {
      this.view_file_reso = null
      this.view_attach_form_reso = false
    },
    add() {
      this.minutes_title = 'New Minutes'
      this.selected = {}
      this.selected.orig_path = null
      this.editable = true
    },
    edit(index) {
      this.minutes_title = 'Edit Minutes'
      this.selected = Object.assign({}, this.minutes[index])
      this.selected.orig_path = this.selected.path
      this.editable = true
    },
    save: function() {
      this.selected.date = this.getFormattedDate(this.selected.date)
      if (this.selected.imp_date) {
        this.selected.imp_date = this.getFormattedDate(this.selected.imp_date)
      }
      var action = this.selected.id ? 'update' : 'create'
      Minutes(this.selected, action).then(res => {
        if (res.data.error === true) {
          Notification.error({
            title: 'Error',
            message: 'Data already existed',
            duration: 5 * 1000
          })
        } else {
          Notification.success({
            title: 'Success',
            message: 'Minutes has been saved successfully',
            duration: 5 * 1000
          })
          this.submitClose('selected')
          this.getAll()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    view(index) {
      this.resolution_selected = Object.assign({}, index)
      this.view_resolution = true
      this.meta_r.page = 1
      this.getListResolutions()
    },
    getListResolutions() {
      this.loading1 = true
      Resolutions({
        page: this.meta_r.page,
        minute_id: this.resolution_selected.id
      }).then(res => {
        this.resolutions = res.data.resolutions
        this.meta_r = res.data.meta
        this.loading1 = false
      }).catch(error => {
        console.log(error)
        this.loading1 = false
      })
    },
    closeListResolutions() {
      this.resolutions = []
      this.view_resolution = false
    },
    getFormattedTimeStamps() {
      return this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    },
    getFormattedDate(date, format = 'YYYY-MM-DD') {
      return this.$moment(date).format(format)
    },
    getFormattedYear(year) {
      return this.$moment(new Date(year)).format('YYYY')
    },
    handleCurrentChange(val) {
      this.currentRow = val
    }
  }
}
</script>
